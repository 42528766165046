<template>
  <div style="width: 100%;padding: 10px">
    <div style="margin: 10px 0 ;display: flex">
      <div class="block" >
<!--            <span class="demonstration">请选择时间</span>-->
        <el-date-picker
            v-model="timeSpase"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="select">
        </el-date-picker>
      </div>
      <el-input style="width: 200px" v-model="selectKey"></el-input>
      <el-button @click="getTableDateShow">筛选</el-button>
      <el-button @click="selectKey='',getTableDateShow()">重置</el-button>
    </div>
    <el-table
        :data="tableDataShow"
        border
        style="width: 100%">
      <el-table-column
          v-if="false"
          fixed="left"
          label="id"
          prop="id"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="工件型号"
          prop="serialName"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="图纸号"
          prop="paperNumber"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="厂商"
          prop="factory"
          width="100">
      </el-table-column>
      <el-table-column
          label="工件数量"
          prop="number"
          width="100">
      </el-table-column>
      <el-table-column
          label="入库数量"
          prop="chengpinNum"
          width="100">
      </el-table-column>
      <el-table-column
          label="工序1"
          prop="process[0]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序2"
          prop="process[1]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序3"
          prop="process[2]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序4"
          prop="process[3]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序5"
          prop="process[4]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序6"
          prop="process[5]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序7"
          prop="process[6]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序8"
          prop="process[7]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序9"
          prop="process[8]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序10"
          prop="process[9]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序11"
          prop="process[10]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序12"
          prop="process[11]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序13"
          prop="process[12]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序14"
          prop="process[13]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序15"
          prop="process[14]"
          width="80">
      </el-table-column>
      <el-table-column
          label="入厂时间"
          prop="intime"
          width="130">
      </el-table-column>
      <el-table-column
          label="完工时间"
          prop="outtime"
          width="130">
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          :current-page="currentPage4"
          :page-size="size"
          :page-sizes="[5, 10, 20]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>

  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: "InboundOverview",
  data() {
    return {
      paperNumber: "",
      serialName: "",
      factory: "",
      form: {},
      dialogVisible: false,
      search: '',
      currentPage4: 1,
      size: 10,
      total: 0,
      tableData: [],
      tableDataShow: [],
      upOrNew: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeSpase: '',

      selectKey: ""
    }
  },
  //加载表格
  mounted() {
    this.timeSpase = [(new Date()).getTime() - 3600 * 1000 * 24 * 30 , new Date()]
    this.select()
  },
  methods: {
    //新增
    add() {
      this.dialogVisible = true
      this.form = {}
      this.upOrNew = 'new'
    },
    //整表查询
    select() {
      /*request.post("/GetTotal").then(res => {
        this.total = res.data;
      })*/
      request.post("/InboundOverview",this.timeSpase).then(res => {
        this.tableData = res.data
        this.total = res.data.length
        this.getTableDateShow()
      })
    },
    //关键字查询
    selectkey() {
      var selectform = {
        factory:this.factory,
        serialName:this.serialName,
        paperNumber:this.paperNumber
      }
      request.post("/InboundOverviewSelect",selectform).then(res => {
        this.tableData = res.data
        this.total = res.data.length
        this.getTableDateShow()
      })
    },
    //重置
    remove() {
      this.search = ''
      this.select();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableDateShow()
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.getTableDateShow()
    },
    getTableDateShow(){

      let _tableData = this.tableData.concat()
      this.total = _tableData.length
      if (this.selectKey !== ""){
        _tableData = _tableData.filter(item => (item.serialName.toLowerCase().includes(this.selectKey.toLowerCase()) || item.paperNumber.toLowerCase().includes(this.selectKey.toLowerCase())))

        this.total = _tableData.length
      } else {
        _tableData = _tableData
      }


      let _pageMax = Math.ceil(this.total/this.size)
      this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
      this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4


      this.tableDataShow = _tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
    },
    handleEdit(row) {
    },
  }
}






</script>

<style scoped>

</style>